import Box from 'components/Box/Box'
import Page from 'components/Page'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Flex from 'components/Box/Flex'
import { CollectionDetail } from 'config/types/collection'
import { BannerDetail, HomeCategory } from 'config/types/home'
import useFilterBannerCallback from 'hooks/useFilterBannerCallback'
import { useTranslation } from 'react-i18next'
import { useMenuContainer } from 'state/app/hooks'
import { breakpointMap } from 'theme/base'
import { buildResponsiveStyledSystemWithExceptedComponent } from 'utils/styled'
import LiveBet from 'views/GameDetails/LiveBet'
import GameCollection from './components/GameCollection'
import GameProvider from './components/GameProvider'
import GameTags from './components/GameTags'
import HomeCarousel from './components/HomeCarousel'
import SectionTitle from './components/SectionTitle'
import SmallBanner from './components/SmallBanner'
import SubBanner from './components/SubBanner'
import TopWinList from './components/TopWinList'

type HomeProps = {
  banners: { mainBanner: BannerDetail[]; subBanner: BannerDetail[]; smallBanner: BannerDetail[] }
  collections: CollectionDetail[]
  categories: HomeCategory[]
}

const Home: React.FC<HomeProps> = ({ banners: { mainBanner, subBanner, smallBanner }, collections, categories }) => {
  const { t } = useTranslation()
  const { menuWidth } = useMenuContainer()
  const filterCallback = useFilterBannerCallback()
  const [firstCollection, secondCollection, thirdCollection, fourCollection, ...otherCollections] = collections

  const [filteredMainBanner, filteredSubBanner, filteredSmallBanner] = useMemo(() => {
    const filteredMainBanner = filterCallback(mainBanner)
    const filteredSubBanner = filterCallback(subBanner)
    const filteredSmallBanner = filterCallback(smallBanner)

    return [filteredMainBanner, filteredSubBanner, filteredSmallBanner]
  }, [filterCallback])

  return (
    <Wrapper>
      <HomeCarousel banners={filteredMainBanner} />
      <Page>
        <GameTags mb={['20px', '', '32px']} mx="auto" categories={categories} />
        <SubBanner banners={filteredSubBanner} />

        <GameCollection
          mt="20px"
          isFeature
          row={2}
          col={buildResponsiveStyledSystemWithExceptedComponent([2, 3, 4, 5, 6, 6, 7], menuWidth, breakpointMap.sm)}
          gap={[6, 6, 6, 6, 10]}
          collection={firstCollection}
        />

        <GameCollection
          my={['20px', '', '40px']}
          row={1}
          col={buildResponsiveStyledSystemWithExceptedComponent([2, 3, 4, 5, 6, 6, 7], menuWidth, breakpointMap.sm)}
          gap={[6, 6, 6, 6, 10]}
          collection={secondCollection}
        />

        <SmallBanner smallBanner={filteredSmallBanner && filteredSmallBanner[0]} />

        <GameCollection
          mt={['20px', '', '40px']}
          row={2}
          isFeature
          col={buildResponsiveStyledSystemWithExceptedComponent([2, 3, 4, 5, 6, 6, 7], menuWidth, breakpointMap.sm)}
          gap={[6, 6, 6, 6, 10]}
          collection={thirdCollection}
        />

        <GameProvider mt={['20px', '', '40px']} />
        <Flex flexWrap="wrap">
          <Flex
            width="100%"
            maxWidth={buildResponsiveStyledSystemWithExceptedComponent(
              ['100%', '100%', '100%', 'calc(50% - 10px)', 'calc(60% - 10px)', 'calc(66.66% - 10px)'],
              menuWidth,
              breakpointMap.sm,
            )}
          >
            <GameCollection
              width="100%"
              mt={['20px', '', '40px']}
              row={2}
              col={buildResponsiveStyledSystemWithExceptedComponent([2, 3, 2, 3, 4], menuWidth, breakpointMap.sm)}
              gap={[6, 6, , 6, 6, 10]}
              collection={fourCollection}
            />
          </Flex>

          <Flex
            maxWidth={buildResponsiveStyledSystemWithExceptedComponent(
              ['100%', '100%', '100%', 'calc(50% + 10px)', 'calc(40% + 10px)', 'calc(33.34% + 10px)'],
              menuWidth,
              breakpointMap.sm,
            )}
            width={'100%'}
            mt={['20px', '', '40px']}
            pl={['', '', '', '20px', '20px', '20px', '40px']}
            flexDirection="column"
          >
            <SectionTitle title={t('Top Win')} mb={['10px', '', '', '0px']} />
            <TopWinList />
          </Flex>
        </Flex>

        <LiveBet my={[20, 20, 20, 40]} />

        {otherCollections.map((collection) => (
          <GameCollection
            key={collection.id}
            mt={['20px', '', '40px']}
            col={buildResponsiveStyledSystemWithExceptedComponent([2, 3, 4, 5, 6, 6, 7], menuWidth, breakpointMap.sm)}
            gap={[6, 6, 6, 6, 10]}
            collection={collection}
          />
        ))}
      </Page>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  box-sizing: border-box;
  position: relative;
  justify-content: center;

  width: 100%;
`

export default Home
