import { BannerDetail } from 'config/types/home'
import React, { useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Image from 'components/Image'
import Link from 'components/Link'
import Flex from 'components/Box/Flex'
import { FlexProps } from 'styled-system'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import Box from 'components/Box/Box'
import Text from 'components/Text'
import { Trans, useTranslation } from 'react-i18next'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/autoplay'
import Carousel from 'components/Carousel'
import { targetBanner } from 'config/constants/banner'
import { useResponsiveLayout } from 'state/app/hooks'
import { detectResponsiveBreakpoint } from 'utils/styled'
import Button from 'components/Button'
import { Icons } from 'svgs'

const SubBanner: React.FC<{ banners: BannerDetail[] } & FlexProps> = ({ banners = [], ...props }) => {
  const { t } = useTranslation()
  const breakPoints = useMatchBreakpoints()
  const responsive = useResponsiveLayout()
  const { isMobile, isDesktop, isXl } = breakPoints
  const controlledRef = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)

  const slidePerView = useMemo(() => {
    const slideDisplayResponsive = () => {
      if (banners.length < 5) {
        return [2.2, 2.2, 2.2, 3.2, 3.2, 3.2, 4]
      }

      if (banners.length >= 5) {
        return [2.2, 2.2, 2.2, 3.2, 3.2, 3.2, 4.2]
      }
    }

    const responsiveConfig = responsive(slideDisplayResponsive())
    return detectResponsiveBreakpoint(responsiveConfig)
  }, [breakPoints, responsive])

  const slides = useMemo(
    () =>
      banners.map((banner) => ({
        key: `sub-banner-${banner.id}`,
        content: (
          <StyledWrapLinkBanner
            external={banner.target === targetBanner[2]}
            aria-label={`${banner.id}`}
            href={banner.url}
            height="100%"
            disabledDefaultStyle
            $hoverBackground={banner?.subtitleInfo?.backgroundColor}
          >
            <Flex flexDirection="column" justifyContent="center" width="100%" p="8px" height="100%" my="auto">
              <Flex
                width={['120px', '120px', '120px', '120px', '120px', '120px', '120px', '140px']}
                height={['80px', '80px', '80px', '80px', '80px', '80px', '80px', '100px']}
              >
                <StyledImage src={banner.imageSrc} alt="banner" width={140} height={100} blurLoader />
              </Flex>
              <Box p="8px" width="100%" height="100%" mt="8px">
                <Text
                  fontSize="16px"
                  fontWeight={600}
                  lineHeight="normal"
                  letterSpacing="-0.32px"
                  mb="8px"
                  wordBreak="break-word"
                >
                  {t(banner?.title)}
                </Text>
                {banner?.subtitleInfo && (
                  <Text
                    fontSize="12px"
                    color="textSubtle"
                    fontWeight={500}
                    letterSpacing="-0.24px"
                    lineHeight="normal"
                    ellipsis={banner?.title.length > 35 ? 1 : 2}
                  >
                    {t(banner?.subtitleInfo?.content)}
                  </Text>
                )}
              </Box>
            </Flex>
          </StyledWrapLinkBanner>
        ),
      })),
    [banners, t],
  )

  return (
    <Wrapper {...props}>
      {!isMobile ? (
        <Box width="100%">
          <>
            {slidePerView < banners.length && (
              <Flex width="100%" justifyContent="flex-end" mb="12px" pr="20px">
                <StyledScrollButtonGroup>
                  <StyledIconButton
                    onClick={() => {
                      controlledRef.current.slideToLoop(activeSlide - 1)
                    }}
                    title="scroll-right-to-left"
                  >
                    <Icons.ChevronLeftIcon width="16px" height="16px" />
                  </StyledIconButton>
                  <StyledIconButton
                    ml="8px"
                    onClick={() => {
                      controlledRef.current.slideToLoop(activeSlide + 1)
                    }}
                    title="scroll-left-to-right"
                  >
                    <Icons.ChevronRightIcon width="16px" height="16px" />
                  </StyledIconButton>
                </StyledScrollButtonGroup>
              </Flex>
            )}
          </>

          <Carousel
            pr="20px"
            pb="200px"
            autoplay={
              slidePerView < banners.length
                ? {
                    delay: 6000,
                  }
                : false
            }
            slides={slides}
            slidesPerView={slidePerView}
            spaceBetween={isDesktop && !isXl ? 20 : 12}
            onSwiper={(swiper) => {
              controlledRef.current = swiper
            }}
            onActiveSlideChange={(index) => setActiveSlide(index)}
          />
        </Box>
      ) : (
        <>
          <ContainerSubTitleMobile width="100%">
            {banners.map((item) => (
              <>
                {item?.subtitleInfo?.spaceBanner ? (
                  <ContainerBackground width="100%">
                    <BackgroundSubTitleMobile
                      external={item.target === targetBanner[2]}
                      disabledDefaultStyle
                      href={item.url}
                      $hoverBackground={item?.subtitleInfo?.backgroundColor}
                    >
                      <Flex width="100%" justifyContent="space-between" alignItems="center" minHeight="90px">
                        <Flex width="30%" height="100%" justifyContent="center">
                          <Box width="75px" height="54px">
                            <Image src={item?.mobileImageSrc} alt="banner" width={75} height={54} blurLoader />
                          </Box>
                        </Flex>

                        <Box p="12px" width="80%">
                          <Box p="4px">
                            <Text
                              fontSize="16px"
                              fontWeight={600}
                              lineHeight="normal"
                              letterSpacing="-0.32px"
                              ellipsis={1}
                              mb="8px"
                            >
                              <Trans>{item.title}</Trans>
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight={500}
                              lineHeight="normal"
                              letterSpacing="-0.24px"
                              color="textSubtle"
                              ellipsis={2}
                            >
                              <Trans>{item?.subtitleInfo?.content}</Trans>
                            </Text>
                          </Box>
                        </Box>
                      </Flex>
                    </BackgroundSubTitleMobile>
                  </ContainerBackground>
                ) : (
                  <ContainerBackground width="calc(50% - 4px)">
                    <BackgroundSubTitleMobile
                      external={item.target === targetBanner[2]}
                      disabledDefaultStyle
                      href={item.url}
                      $hoverBackground={item?.subtitleInfo?.backgroundColor}
                      minWidth="168px"
                    >
                      <Box minHeight="79px">
                        <Flex width="75px" height="54px" justifyContent="center">
                          <Image src={item?.mobileImageSrc} alt="banner" width={75} height={54} blurLoader />
                        </Flex>

                        <Box px="12px">
                          <Box p="4px">
                            <Text
                              fontSize="16px"
                              fontWeight={600}
                              lineHeight="normal"
                              letterSpacing="-0.32px"
                              wordBreak="break-all"
                              ellipsis={1}
                            >
                              <Trans>{item?.title}</Trans>
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </BackgroundSubTitleMobile>
                  </ContainerBackground>
                )}
              </>
            ))}
          </ContainerSubTitleMobile>
        </>
      )}
    </Wrapper>
  )
}

const StyledImage = styled(Image)``

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
`

const StyledWrapLinkBanner = styled(Link)<{ $hoverBackground?: any }>`
  border-radius: 12px;
  background: linear-gradient(180deg, rgba(45, 45, 45, 0.5) 0%, rgba(173, 173, 173, 0.1) 100%);
  overflow: hidden;
  cursor: pointer;

  &:not(:hover) {
    @keyframes reverseScale {
      from {
        transform: scale(1.15);
      }
      to {
        transform: scale(1);
      }
    }

    ${StyledImage} {
      animation: reverseScale 0.3s ease-in-out forwards;
    }
  }

  &:hover {
    @keyframes scales {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.15);
      }
    }

    ${StyledImage} {
      animation: scales 0.3s ease-in-out forwards;
    }

    ${({ $hoverBackground }) =>
      $hoverBackground &&
      css`
        background: linear-gradient(320deg, #2d2d2d7f 0%, rgba(173, 173, 173, 0.1) 100%),
          radial-gradient(circle 450px at 20% 10%, ${$hoverBackground}80, transparent 30%);
      `};
  }
`

const ContainerSubTitleMobile = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  column-gap: 8px;
`

const BackgroundSubTitleMobile = styled(Link)<{ $hoverBackground?: any }>`
  border-radius: 12px;
  padding-block: 8px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
  min-height: 106px;

  ${({ $hoverBackground }) =>
    $hoverBackground
      ? css`
          background: linear-gradient(320deg, #2d2d2d7f 0%, rgba(173, 173, 173, 0.1) 100%),
            radial-gradient(circle 450px at 20% 10%, ${$hoverBackground}80, transparent 30%);
        `
      : css`
          background: linear-gradient(180deg, rgba(45, 45, 45, 0.5) 0%, rgba(173, 173, 173, 0.1) 100%);
        `};
`

const ContainerBackground = styled(Flex)`
  a {
    width: 100% !important;
  }
`

const StyledIconButton = styled(Button)`
  background: ${({ theme }) => theme.colors.backgroundAlt}80 !important;
  border-radius: 50%;
  padding: 0px;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }

  &:disabled {
    svg {
      fill: ${({ theme }) => theme.colors.textDisable} !important;
    }
  }
`

const StyledScrollButtonGroup = styled(Flex)`
  display: flex;
`

export default SubBanner
