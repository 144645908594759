import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import { CollectionDetail } from 'config/types/collection'
import { ResponsiveGameCollection } from 'config/types/game'
import useComponentOnScreen from 'hooks/useComponentOnScreen'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useRequest } from 'hooks/useRequest'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import GameService from 'services/GameService'
import { parselistToMatrix } from 'utils'
import SectionTitle from './SectionTitle'
import { StyledContainerGameCollection, StyledGameBannerItem, StyledGridGame } from './styles'

interface GameCollectionProps extends ResponsiveGameCollection {
  collection: CollectionDetail
}

const GameCollection: React.FC<GameCollectionProps & BoxProps> = ({
  collection,
  isFeature,
  row,
  col,
  gap,
  ...props
}) => {
  const { isMobile } = useMatchBreakpoints()
  const isShowFeatureGame = isMobile ? false : isFeature

  const [games, setGames] = useState(null)
  const parsedRow = row || (games?.length > 12 ? 2 : 1)
  const { execute } = useRequest()

  const { isLoaded, ref } = useComponentOnScreen({
    root: null,
    rootMargin: '200px',
    threshold: 0,
  })

  const { featuredGame, matrix } = useMemo(() => {
    const gameDisplays = games || Array(10)
    const [featuredGame, ...otherGames] = isShowFeatureGame ? gameDisplays : [null, ...gameDisplays]

    return {
      featuredGame,
      matrix: parselistToMatrix(otherGames, parsedRow),
    }
  }, [games, isMobile, parsedRow])

  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const fetch = async () => {
      if (isLoaded && collection) {
        const response = await execute(GameService.getGameCollectionDetail(collection.code, false))
        if (response?.data) setGames(response.data.items)
      }
    }

    fetch()
  }, [isLoaded])

  return (
    collection && (
      <Box ref={ref} {...props} overflow="hidden">
        <SectionTitle
          title={<Trans>{collection.name}</Trans>}
          scrollRef={games && containerRef}
          mb={['10px', '', '', '0px']}
        />
        <StyledContainerGameCollection
          col={col}
          gap={gap}
          row={parsedRow}
          isFeature={isShowFeatureGame}
          itemCount={games?.length || 10}
        >
          <StyledGridGame ref={containerRef}>
            {isShowFeatureGame ? (
              featuredGame ? (
                <StyledGameBannerItem game={featuredGame} large />
              ) : (
                <Box paddingBottom="100%" />
              )
            ) : (
              <></>
            )}

            {matrix.map((rowData, index) => {
              return (
                <Box key={`game-matrix-row-${collection.id}-${index}`}>
                  {rowData.map((game, gameIndex) =>
                    game ? (
                      <StyledGameBannerItem key={`game-${collection.id}-${gameIndex}`} game={game} />
                    ) : (
                      <Box paddingBottom="100%" key={`game-loader-${collection.id}-${gameIndex}`} />
                    ),
                  )}
                </Box>
              )
            })}
          </StyledGridGame>
        </StyledContainerGameCollection>
      </Box>
    )
  )
}

export default GameCollection
