import { BannerTypeEnum } from 'config/constants/banner'
import { APIEndpointEnum } from 'config/constants/server'
import { getDefaultMeta } from 'config/meta'
import ServerFetcher from 'services/ServerFetcher'
import { buildSeoProps, fetchMetaTag } from 'services/serverSide/metaTag'
import { ServerTranslator } from 'services/serverSide/serverTranslator'
import { forkjoinRequest } from 'utils/requestHelper'
import { SentryHelper } from 'utils/sentryHelper'
import Home from 'views/Home'

const HomePage = ({ banners, collections, categories }) => {
  return <Home banners={banners} collections={collections} categories={categories} />
}

export async function getStaticProps({ locale }) {
  await ServerTranslator.init()

  const [bannerRes, collectionRes, categoryRes, metaTag] = await forkjoinRequest([
    ServerFetcher.fetch(
      APIEndpointEnum.BannerList,
      {
        paging: {
          offset: 0,
          limit: 100,
        },
      },
      { cacheDuration: 120 },
    ),
    ServerFetcher.fetch(
      APIEndpointEnum.GameCollectionList,
      {
        paging: {
          offset: 0,
          limit: 100,
        },
      },
      { cacheDuration: 120 },
    ),
    ServerFetcher.fetch(APIEndpointEnum.HomeCategoryList, {}, { cacheDuration: 300 }),
    fetchMetaTag('', locale),
  ])

  if (!bannerRes || !collectionRes || !categoryRes) {
    SentryHelper.captureFeatureServerError({
      feature: 'Homepage',
      error: new Error('Cannot init home page data'),
    })
    return {
      props: {
        banners: { mainBanner: [], subBanner: [], smallBanner: [] },
        categories: [],
        layoutProps: {
          maxWidth: '1920px',
          px: '0px !important',
          pt: '0px',
          mt: ['0 !important', '', '', '', '', '-65px !important'],
        },
        collections: [],
        seoProps: buildSeoProps(metaTag, getDefaultMeta(ServerTranslator.buildTFn(locale), locale)),
        revalidate: 2,
      },
    }
  }
  const banners = bannerRes.data
  const mainBanner = banners?.[BannerTypeEnum.MainBanner] || []
  const subBanner = banners?.[BannerTypeEnum.SubBanner] || []
  const smallBanner = banners?.[BannerTypeEnum.SmallBanner] || []

  // Pass data to the page via props
  return {
    props: {
      banners: { mainBanner, subBanner, smallBanner },
      categories: categoryRes.data?.items,
      layoutProps: {
        maxWidth: '1920px',
        px: '0px !important',
        pt: '0px',
        mt: ['0 !important', '', '', '', '', '-65px !important'],
      },
      collections: collectionRes.data.items,
      seoProps: buildSeoProps(metaTag, getDefaultMeta(ServerTranslator.buildTFn(locale), locale)),
    },
    revalidate: 30,
  }
}

export default HomePage
